import axios from "axios";
import { toast } from "react-toastify";

import config from "../util/config";
import scribe from "../util/scribe";

axios.interceptors.response.use(null, (e) => {
  if (!(e.response && e.response.status >= 400 && e.response.status < 500)) {
    scribe.error(e?.response || e);
    toast.error(
      "Une erreur inattendue s'est produite; consultez l'administratrice système."
    );
  }
  return Promise.reject(e?.response || e);
});

const options = {};

const baseUrl = config.apiUrl;

function setJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
}

const traverse = (obj) => {
  if (obj)
    for (const [k, v] of Object.entries(obj)) {
      if (v && typeof v === "object" && !Array.isArray(v)) traverse(v);
      if (
        k === "moment" &&
        typeof v === "string" &&
        v?.match?.(
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z|([+-]\d{2}:?\d{2})$/
        )
      ) {
        obj[k] = new Date(v);
      }
    }
};

async function get(url) {
  // console.log(`xhr::GET ${baseUrl + url}`);
  const { data } = await axios.get(baseUrl + url, options);
  if (data && Array.isArray(data)) {
    for (const row of data) {
      traverse(row);
    }
  } else {
    traverse(data);
  }
  return data;
}

async function post(url, data) {
  // console.log("xhr::post, url:", baseUrl + url);
  // console.log("xhr::post, data:", data);
  const { data: created } = await axios.post(baseUrl + url, data, options);
  return created;
}

async function put(url, data) {
  // console.log("xhr::put, url:", baseUrl + url);
  // console.log("xhr::put, data:", data);
  // console.log("Auth header:", axios.defaults.headers.common["Authorization"]);
  const { data: updated } = await axios.put(baseUrl + url, data, options);
  return updated;
}

async function del(url) {
  const { data } = await axios.delete(baseUrl + url, options);
  return data;
}

const exports = {
  get,
  post,
  put,
  setJwt,
  del,
};

export default exports;
